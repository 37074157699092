import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};

const ContactForm = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  history,
  ...props
}) => {

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Entre em contato conosco',
    paragraph: ''
  };

  const createFormDataObj = data => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
    return formData;
  };

  const handleInputChange = (event, stateFunc) =>
    stateFunc(event.target.value);

  const handleSubmit = async event => {
    event.preventDefault();

    const data = {
      'form-name': 'contact',
      name: name,
      email: email,
      message: message,
    };

    await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(createFormDataObj(data)).toString()
    })
      .then(() => history.push('/submitted'))
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <section
      {...props}
      className={`${outerClasses} mb-32`}
    >
      <div className="container" id="contato">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="reveal-from-right ml-32 mr-32" data-reveal-delay="200" style={{ width: "100%" }}>
              <div className="container p-32">
                <form onSubmit={handleSubmit} name="contact" method="POST" action="/submitted">
                  <input type="hidden" name="form-name" value="contact" />
                  <Input
                    className="contact-form-field mb-8"
                    name="name"
                    label="name"
                    labelHidden
                    placeholder="Seu nome"
                    onChange={event => handleInputChange(event, setName)} 
                  />
                  <Input
                    className="contact-form-field mb-8"
                    name="email"
                    label="email"
                    labelHidden
                    placeholder="Seu melhor e-mail"
                    onChange={event => handleInputChange(event, setEmail)} 
                  />
                  <Input 
                    className="contact-form-field message-area mb-8"
                    name="message"
                    type="textarea"
                    label="message"
                    labelHidden 
                    placeholder="Sua mensagem"
                    onChange={event => handleInputChange(event, setMessage)}
                  />
                  <Button className='mt-16' tag="button" color="primary" type="submit">Enviar</Button>
                </form>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>
  );
};

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default withRouter(ContactForm);