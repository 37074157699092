import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: '',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <h1 className="testimonial-highlight">56%</h1>
                  <p className="text-sm mb-0">
                    dos CEOs de grandes empresas relatam aumento de receita com a adoção de soluções digitais no seu negócio
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                    Fonte:&nbsp;&nbsp;
                    <a href="https://www.clickz.com/digital-transformation-2019-primer/224247/">
                      Digital transformation: What it is and why it matters
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <h1 className="testimonial-highlight">95%</h1>
                  <p className="text-sm mb-0">
                    é a projeção do crescimento global da adoção de soluções baseadas em automação digital nos próximos dois anos
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                    Fonte:&nbsp;&nbsp;
                    <a href="https://www.e-zigurat.com/innovation-school/blog/digital-transformation-trends-2020/">
                      TOP 5 Digital Transformation Trends for 2020
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                <h1 className="testimonial-highlight">15,3%</h1>
                <p className="text-sm mb-0">
                das grandes e médias empresas brasileiras já inserem a adoção de soluções de inteligência artificial entre as suas principais iniciativas de TI (e a expectativa é esse número dobrar nos próximos 4 anos)
                </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                    Fonte:&nbsp;&nbsp;
                    <a href="https://computerworld.com.br/2019/08/19/mercado-de-ti-brasileiro-cresce-acima-da-media-global-aponta-abes/">
                      Mercado de TI brasileiro cresce acima da média global, aponta ABES
                    </a>
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;