import React from 'react';
import { Link } from 'react-router-dom';
import Modal from '../components/elements/Modal';
import Button from '../components/elements/Button';

const SubmitConfirmation = () => {
  return (
    <>
      <Modal show={true} closeHidden>
        <div>
          <h4>Sua mensagem foi enviada</h4>
          <p>
            Obrigado por entrar em contato. Temos seu email e responderemos em breve.
          </p>
          <Link to='/'>
            <Button color="primary">OK</Button>
          </Link>
        </div>
      </Modal>
    </>
  )
};

export default SubmitConfirmation;