import React from 'react';
import { Link } from 'react-router-dom';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import ContactForm from '../components/sections/ContactForm';
import Testimonial from '../components/sections/Testimonial';
import Explanation from '../components/sections/Explanation';
import GenericSection from '../components/sections/GenericSection';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <Testimonial />
      <Explanation />
      <FeaturesTiles />
      <GenericSection>
        <div className="react-router-link-container">
          <Link to="/service"><h3 className="react-router-link">Saiba Mais</h3></Link>
        </div>
      </GenericSection>
      <ContactForm />
    </>
  );
}

export default Home;