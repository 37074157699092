import React from 'react';
import Image from '../components/elements/Image';
import ServiceHero from '../components/sections/ServiceHero';
import GenericSection from '../components/sections/GenericSection';
import ContactForm from '../components/sections/ContactForm';

const Home = () => {

  return (
    <>
      <ServiceHero 
        mainTitle='Da ideação à implementação' 
        highlight='
          Hoje, o uso de software define as interações 
          de qualquer tipo de negócio com seus clientes 
          e parceiros. Somos capacitados para  moldar e 
          entregar soluções de acordo com a demanda, atendendo
          padrões de qualidade e custo competitivo
        '
      />
      <GenericSection>
        <p>
          O valor de quase todos os produtos hoje vêm de software. 
          Mas a construção desses produtos habilitados por software
          apresenta novos desafios. Requer recursos de tecnologia de ponta,
          colaboração estreita, retorno rápido e uma verdadeira compreensão
          dos requisitos do mercado e dos clientes. Temos a expertise necessária
          para ajudar negócios de todos os tipos a utilizar essa nova ferramenta.
        </p>
      </GenericSection>
      <GenericSection>
        <h3 style={{ marginBottom: '3.5rem' }}>O Que Nós Fazemos</h3>
        <div className="pb-32 mb-32 mt-16">
          <div className="icon p-16">
            <Image
              src={require('./../assets/images/webdev.svg')}
              alt="icon chatbot"
              width={64}
              height={64}
            />  
          </div>
          <h4>Desenvolvimento Web</h4>
          <p>
            Projetamos e construímos uma presença online robusta com 
            o uso de melhores práticas da indústria como design responsivo 
            e <i>mobile first</i>. 
          </p>
        </div>
        <div className="pb-32 mb-32">
          <div className="icon p-16">
            <Image
              src={require('./../assets/images/ecommerce.svg')}
              alt="icon chatbot"
              width={64}
              height={64}
            />  
          </div>
          <h4>E-Commerce</h4>
          <p>
            Somos capazes de implementar soluções simples e complexas sob medida
            para comércios de qualquer porte que pretendam vender pela Internet. 
            Ajudamos a empresa a avaliar suas necessidades e implementar a solução adequada. 
          </p>
        </div>
        <div className="pb-32 mb-32">
          <div className="icon p-16">
            <Image
              src={require('./../assets/images/digital_marketing.svg')}
              alt="icon chatbot"
              width={64}
              height={64}
            />  
          </div>
          <h4>Marketing Digital</h4>
          <p>
            São atividades que utilizam a internet para promover produtos e marcas, 
            direcionando a comunicação assertiva com o público-alvo, atração de novos 
            negócios bem como o desenvolvimento de uma identidade visual. 
          </p>
        </div>
        <div className="pb-32 mb-32">
          <div className="icon p-16">
            <Image
              src={require('./../assets/images/SEO.svg')}
              alt="icon chatbot"
              width={64}
              height={64}
            />  
          </div>
          <h4>SEO</h4>
          <p>
            Otimizamos a performance de rankeamento de sites em resultados de busca, 
            o que pode alavancar o desempenho de conversões e otimizar o retorno do 
            investimento em marketing digital.
          </p>
        </div>
        <div className="pb-32 mb-32">
          <div className="icon p-16">
            <Image
              src={require('./../assets/images/chatbot.svg')}
              alt="icon chatbot"
              width={64}
              height={64}
            />  
          </div>
          <h4>Chatbot</h4>
          <p>
            Ajudamos a estabelecer um relacionamento com o cliente 
            de maneira rápida e eficaz por meio da inteligência artificial 
            empregada na programação de mensagens mais recorrentes, otimizando 
            tempo para a resolução de problemas complexos. O diferencial desse 
            serviço é a automatização parcial ou total dos setores da empresa, 
            propiciando um atendimento escalável. 
          </p>
        </div>
        <div className="pb-32 mb-32">
          <div className="icon p-16">
            <Image
              src={require('./../assets/images/data_analysis.svg')}
              alt="icon chatbot"
              width={64}
              height={64}
            />  
          </div>
          <h4>Análise de Dados</h4>
          <p>
            Realizamos minuciosamente a análise de fontes de informações na identificação 
            e compreensão do desempenho do seu negócio para a tomada de decisões e 
            posicionamento estratégico. Nesse processo, empregamos ferramentas técnicas 
            aliados à softwares a fim de garantir análises com um universo de dados ampliados, 
            tornando os resultados mais precisos e confiáveis. 
          </p>
        </div>
        <div className="pb-32 mb-32">
          <div className="icon p-16">
            <Image
              src={require('./../assets/images/cloud.svg')}
              alt="icon chatbot"
              width={64}
              height={64}
            />  
          </div>
          <h4>Computação em Nuvem</h4>
          <p>
            Possibilita a hospedagem de serviços, programas e dados em grande escala na internet, 
            melhorando a experiência e acessibilidade à informação. A adoção da computação na nuvem 
            trazem alguns benefícios, como: redução no investimento em infraestrutura, centralização 
            da informação, economia de espaço bem como o trabalho remoto. 
          </p>
        </div>
      </GenericSection>
      <GenericSection>
        <h3 style={{ marginBottom: '3.5rem' }}>Como Fazemos</h3>
        <p>Os Pilares do nosso processo de desenvolvimento são:</p>
        <div className="pb-32 mb-32">
          <h4>Design da Experiência do Usuário (UX)</h4>
          <div className="p-16 mb-32">
            <Image
              src={require('./../assets/images/UX_infographic.svg')}
              alt="icon chatbot"
              width="100%"
            />  
          </div>
          <p>
            Realizamos minuciosamente a análise de fontes de informações na identificação 
            e compreensão do desempenho do seu negócio para a tomada de decisões e 
            posicionamento estratégico. Nesse processo, empregamos ferramentas técnicas 
            aliados à softwares a fim de garantir análises com um universo de dados ampliados, 
            tornando os resultados mais precisos e confiáveis. 
          </p>
        </div>
        <div className="pb-32 mb-32">
          <h4>Desenvolvimento Ágil</h4>
          <div className="p-16 mb-32">
            <Image
              src={require('./../assets/images/agile_infographic.svg')}
              alt="icon chatbot"
              width="100%"
            />  
          </div>
          <p>
            Nossa metodologia consiste na realização de entregas periódicas de um projeto 
            em ciclos de avaliação e reavaliação dos requerimentos e definição de 
            prioridades através do gerenciamento da equipe, dedicada a fazer entregas 
            incrementais de modo a realizar melhorias contínuas da solução oferecida. 
          </p>
        </div>
      </GenericSection>
      <ContactForm />
    </>
  );
}

export default Home;