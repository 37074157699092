import React from 'react';
import GenericSection from './GenericSection';

const Explanation = () => (
  <GenericSection>
    <p>
      A transformação digital tem sinalizado um novo ciclo de mudanças no ambiente de negócios. Ela envolve a integração de tecnologias digitais em todas as áreas das empresas, permitindo a exploração de novos potenciais de crescimento. Na prática, ela é um processo típico de inovação e design iterativo, cíclico e contínuo.
    </p>
    <p>
      Em alguns casos, se trata de adotar uma nova infra-estrutura. Em outros, de uma reorganização de processos diversos unindo o entendimento do negócio com o das necessidades da organização. Ao entender o contexto e a demanda, podemos ajudar a construir uma nova dinâmica interna e uma nova cultura de relacionamento da empresa com seus parceiros e clientes.
    </p>
  </GenericSection>
);

export default Explanation;